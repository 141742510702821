import { put, takeLatest, call } from 'redux-saga/effects';
import { GET_ABOUT_PAGE } from './slice';
import { getAboutApi } from 'service/masterDataApi';

export function* getAbout({ payload }: any) {
  try {
    const response = yield call(getAboutApi, payload);
    yield put(GET_ABOUT_PAGE.success(response.data));
  } catch (err) {
    // console.log(err);
    yield put(GET_ABOUT_PAGE.failure());
  }
}

const aboutSagas: any = [takeLatest(GET_ABOUT_PAGE.TRIGGER, getAbout)];

export default aboutSagas;
