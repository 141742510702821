import { BaseXHR } from '@utils/axios';

interface I_OptionsGetToursWithinRadius {
  province: string;
  lat: number;
  lng: number;
  km: number;
}

export const getTourFlycamApi = (payload = {}) =>
  BaseXHR.$get('/project/get-tour-flycam', payload);

export const getToursWithinRadiusApi = (
  payload: I_OptionsGetToursWithinRadius,
) => BaseXHR.$get('/project/get-tour-within-radius', payload);

export const updateViewCountApi = payload =>
  BaseXHR.$put('/scene/view-count', payload);
