import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createRoutine } from 'redux-saga-routines';
import { ContainerState, I_About } from './typesIndex';

// The initial state of the App container
export const initialState: ContainerState = {
  loading: false,
  success: false,
  error: false,
  about: null,
};

export const GET_ABOUT_PAGE = createRoutine('about/getAbout');

const aboutSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {
    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
  },
  extraReducers: {
    [HYDRATE]: (state: ContainerState, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload.about,
    }),

    /**
     * ! GET ABOUT
     */
    [GET_ABOUT_PAGE.TRIGGER]: (
      state,
      // action: PayloadAction<{ id: string }>,
    ) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_ABOUT_PAGE.SUCCESS]: (state, action: PayloadAction<I_About>) => {
      state.about = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_ABOUT_PAGE.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
});

export const { actions, reducer, name: sliceKey } = aboutSlice;

export default reducer;
