import {
  reducer as appReducer,
  sliceKey as sliceKeyApp,
} from '@containers/App/slice';
import {
  reducer as homeReducer,
  sliceKey as sliceKeyHome,
} from '@containers/Home/slice';
import { HYDRATE } from 'next-redux-wrapper';
import { combineReducers } from 'redux';
import {
  reducer as aboutReducer,
  sliceKey as sliceKeyAbout,
} from '@containers/About/slice';
import {
  reducer as sponsorReducer,
  sliceKey as sliceKeySponsor,
} from '@containers/Sponsor/slice';

const combinedReducer = combineReducers({
  [sliceKeyApp]: appReducer,
  [sliceKeyHome]: homeReducer,
  [sliceKeyAbout]: aboutReducer,
  [sliceKeySponsor]: sponsorReducer,
});

export const reducers = (state, action) => {
  if (action.type === HYDRATE) {
    let nextState = {
      ...state,
      ...action.payload,
    };

    if (state.app)
      nextState = {
        ...nextState,
        app: { ...nextState.app, ...state.app },
      };

    return nextState;
  }
  return combinedReducer(state, action);
};
