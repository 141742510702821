import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LayoutWrapper } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  actions,
  GET_LIST_PLACE_CATEGORY,
  GET_LIST_LOCATION,
  GET_ME,
  GET_APP_INFO,
} from '@containers/App/slice';
import {
  selectFaq,
  selectTrans,
  selectLayout,
  selectProvinceSelected,
  selectOptionsFilterListLocation,
  selectTourFlycam,
  selectIsLogged,
  selectInfoUser,
} from '@containers/App/selectors';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';

import dynamic from 'next/dynamic';
import { isServer } from '@utils/helper';

import { parse } from 'cookie';
import { KEY_COOKIE } from '@type/customType';
import { useValidatePermission } from '@utils/hooks/useValidatePermission';

const Toast = dynamic(() => import('./Toast'));
const Footer = dynamic(() => import('./Footer'));
const Header = dynamic(() => import('./Header'));
const Instruct = dynamic(() => import('@containers/Instruct'));
const Faq = dynamic(() => import('@containers/Faq'));
const Contribute = dynamic(() => import('@containers/Contribute'));
const ModalLogin = dynamic(() => import('./ModalLogin'), { ssr: false });

interface Props {
  children: ReactNode;
}

export default function Layout({ children }: Props) {
  const router = useRouter();
  const dispatch = useDispatch();

  const [isShowDialogFaq, setIsShowDialogFaq] = useState(false);
  const [isShowDialogContribute, setIsShowDialogContribute] = useState(false);
  const [isShowDialogInstruct, setIsShowDialogInstruct] = useState(false);

  const { isShowDialogLogin, onCloseDialogLogin, onValidatePermission } =
    useValidatePermission();

  const faq = useSelector(selectFaq);
  const trans = useSelector(selectTrans);
  const layout = useSelector(selectLayout);
  const isLogged = useSelector(selectIsLogged);
  const infoUser = useSelector(selectInfoUser);
  const provinceSelected = useSelector(selectProvinceSelected);
  const tourFlycam = useSelector(selectTourFlycam);
  const optionsFilterListLocation = useSelector(
    selectOptionsFilterListLocation,
  );

  const detailProvinceSelected = useMemo(
    () =>
      layout?.header?.jsonProvinces?.find(
        item => item.slug === provinceSelected,
      ),
    [layout?.header?.jsonProvinces, provinceSelected],
  );

  const cookies = useCallback(
    () => (!isServer ? parse(document.cookie) : {} || {}),
    [],
  );

  const onShowDialogInstruct = useCallback(
    () => setIsShowDialogInstruct(true),
    [],
  );

  const onShowDialogFaq = useCallback(() => setIsShowDialogFaq(true), []);

  const onShowDialogContribute = useCallback(() => {
    onValidatePermission(() => {
      setIsShowDialogContribute(true);
    });
  }, [onValidatePermission]);

  useEffect(() => {
    if (provinceSelected) {
      router.pathname === '/' &&
        router.push({
          pathname: router.pathname,
          query: { province: provinceSelected },
        });
      if (!tourFlycam && detailProvinceSelected)
        dispatch(actions.setTourFlycam(detailProvinceSelected.projectId));
      dispatch(
        GET_LIST_PLACE_CATEGORY({
          provinceSlug: provinceSelected,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinceSelected]);

  useEffect(() => {
    if (router.query.province && typeof router.query.province === 'string')
      dispatch(actions.setProvinceSelected(router.query.province));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    if (cookies()[KEY_COOKIE.TOKEN])
      dispatch(actions.changeIsLogged({ isLogged: true }));
    dispatch(GET_APP_INFO());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (isLogged) {
      dispatch(GET_ME());
      onCloseDialogLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  useEffect(() => {
    provinceSelected && dispatch(GET_LIST_LOCATION(optionsFilterListLocation));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsFilterListLocation, provinceSelected]);

  return (
    <LayoutWrapper>
      <NextSeo
        title={layout?.seo?.title}
        description={layout?.seo?.description}
        openGraph={{
          type: 'website',
          title: layout?.seo?.title,
          description: layout?.seo?.description,
          images: [
            {
              url:
                layout?.seo?.image?.thumbnail_640_jpg ||
                layout?.seo?.image?.thumbnail_720_jpg ||
                layout?.seo?.image?.default,
              width: 800,
              height: 600,
              alt: '',
            },
          ],
        }}
      />

      <Header
        trans={trans}
        layout={layout}
        isLogged={isLogged}
        infoUser={infoUser}
        tourFlycam={tourFlycam}
        onShowDialogFaq={onShowDialogFaq}
        onShowDialogInstruct={onShowDialogInstruct}
        provinceSelected={provinceSelected}
        onShowDialogContribute={onShowDialogContribute}
      />

      <div className="main">{children}</div>

      <Footer layout={layout} trans={trans} />

      {isShowDialogLogin && (
        <ModalLogin
          isVisible={isShowDialogLogin}
          onClose={onCloseDialogLogin}
        />
      )}

      {isShowDialogFaq && (
        <Faq
          isVisible={isShowDialogFaq}
          setIsVisible={setIsShowDialogFaq}
          faq={faq}
        />
      )}

      {isShowDialogContribute && (
        <Contribute
          isVisible={isShowDialogContribute}
          setIsVisible={setIsShowDialogContribute}
          provinces={layout?.header?.jsonProvinces}
        />
      )}
      {isShowDialogInstruct && (
        <Instruct
          isVisible={isShowDialogInstruct}
          setIsVisible={setIsShowDialogInstruct}
        />
      )}

      <Toast />
    </LayoutWrapper>
  );
}
