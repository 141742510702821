import axios, {
  AxiosResponse,
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
} from 'axios';
import Qs from 'qs';
import { KEY_COOKIE, objectType } from 'type';
import { isServer } from './helper';
import { parse } from 'cookie';

const onSuccessInterceptorRequest = async (config: AxiosRequestConfig) => {
  const cookie = !isServer ? parse(document.cookie) : {} || {};
  const token = cookie[KEY_COOKIE.TOKEN];
  if (token) config.headers['Authorization'] = `Bearer ${token}`;

  if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded')
    config.data = new URLSearchParams(config.data);

  config.paramsSerializer = (params: any) =>
    Qs.stringify(params, {
      arrayFormat: 'brackets',
    });

  return config;
};
const onErrorInterceptorRequest = (error: AxiosError) => Promise.reject(error);

const onErrorInterceptorResponse = (error: AxiosError) => {
  if (error.response && error.response.status) {
    if (error.response.status !== 200) {
      console.warn('error');
    }
  }
  return Promise.reject(error);
};
const onSuccessInterceptorResponse = (response: AxiosResponse) => response;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post.Accept = 'application/json';

const _axios: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL || '',
  timeout: 120 * 1000,
  // withCredentials: true, // Check cross-site Access-Control
});

_axios.interceptors.request.use(
  onSuccessInterceptorRequest,
  onErrorInterceptorRequest,
);

_axios.interceptors.response.use(
  onSuccessInterceptorResponse,
  onErrorInterceptorResponse,
);

const _axiosPimCore: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL_PIMCORE || '',
  timeout: 120 * 1000,
  // withCredentials: true, // Check cross-site Access-Control
});

_axiosPimCore.interceptors.request.use(
  onSuccessInterceptorRequest,
  onErrorInterceptorRequest,
);

_axiosPimCore.interceptors.response.use(
  onSuccessInterceptorResponse,
  onErrorInterceptorResponse,
);

/**
 *
 * @NOTE primary methods axios
 *
 */
class AxiosXHRConstructor {
  axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    this.$get = this.$get.bind(this);
    this.$getWithAuth = this.$getWithAuth.bind(this);
    this.$post = this.$post.bind(this);
    this.$put = this.$put.bind(this);
    this.$delete = this.$delete.bind(this);
  }

  public $getWithAuth(url: string, params?: objectType): AxiosPromise {
    return this.axiosInstance.get(url, params);
  }

  public $get(
    url: string,
    params?: objectType,
    config?: objectType,
  ): AxiosPromise {
    return this.axiosInstance.get(url, {
      ...{ params },
      ...config,
    });
  }
  public $post(
    url: string,
    data?: objectType,
    config?: objectType,
  ): AxiosPromise {
    return this.axiosInstance.post(url, data, config);
  }
  public $put(
    url: string,
    data?: objectType,
    config?: objectType,
  ): AxiosPromise {
    return this.axiosInstance.put(url, data, config);
  }
  public $delete(
    url: string,
    data?: objectType,
    config?: objectType,
  ): AxiosPromise {
    // return this.axiosInstance.delete(url, {
    //   data,
    // });

    /**
     * @hotfix {https://github.com/axios/axios/issues/3220}
     */
    return this.axiosInstance.request({
      method: 'delete',
      url,
      data,
      ...config,
    });
  }
}

export const BaseXHR = new AxiosXHRConstructor(_axios);
export const BaseXHRPimCore = new AxiosXHRConstructor(_axiosPimCore);

export default _axios;
