import { ID_KRPANO_CONTAINER } from '@containers/Home/constants';
import styled, { createGlobalStyle, css } from 'styled-components';

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  :root {
    --color-theme: #D1005C;
    --color-red: #E54D42;
    --color-green: #2FD0AC;
    --color-blue: #007AFF;
    --color-yellow: #F0C330;
    --color-red: red;
    --color-smoke: #969FA7;
    --color-dark: #212121;
    --color-dark-2: #393839;
    --color-light: #E3E8EE;
    --transition-regular: all 0.13s ease-in-out;
    --transition-smooth: all 0.5s cubic-bezier(0.04, 1, 0.6, 0.97);
    --transition-smooth-2: all 1.5s cubic-bezier(0.04, 1, 0.6, 0.97);
    --fontSize-h1: 32px;
    --fontSize-h2: 28px;
    --fontSize-h3: 24px;
    --fontSize-h4: 20px;
    --fontSize-h5: 16px;
    --color-1: #75ab45;
    --color-2: #374151;
    --gradient-1: linear-gradient(241deg, #bbd26c 0%, #5a9c36 100%);
  }

  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif; */
    /* font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif; */
    font-family: 'Inter', sans-serif;
    font-size: 14px;
  }

  #__next {
    width: 100%;
    height: calc(100%);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .cursorPointer {
    cursor: pointer !important;
  }

  body {
    > #${ID_KRPANO_CONTAINER} {
      position: absolute !important;
      z-index: 10000 !important;
    }
    .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
      border-right-color: unset;
    }

    .ctn__html {
      * {
        color: inherit;
      }
      p {
        margin-bottom: .75rem;
      }
      img {
        width: auto;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
      }
      figure {
        margin: 0 auto .75rem!important;
        padding: 0!important;
        img {

        }
        figcaption {
          background: rgba(0,0,0,0.1);
          padding: .25rem 0;
          text-align: center;
          > * {
            margin: 0;
          }
        }
        iframe {
          min-height: 240px;
        }
      }
      table {
        border: 1px solid #ddd;
        margin-bottom: .75rem;
      }
      tr {

      }
      td {
        border: 1px solid #ddd;
        padding: .25rem;
      }
    }

    .ant-tooltip {
      &-inner {
        border-radius: 100px;
      }
    }

    .ant-popover {
      padding: 0;
      z-index: 1054 !important;
      &-message,
      &-buttons {
        padding: 10px 12px;
      }
      &-message {
        padding-bottom: 0;
        > .anticon {
          top: 14px;
        }
      }
      &-placement-top > .ant-popover-content > .ant-popover-arrow {
        bottom: -4.8px;
      }
      &-placement-bottom > .ant-popover-content > .ant-popover-arrow {
        top: 8px;
      }
      &-arrow {
        display: none;
        /* border-color: #000!important; */
      }
      &-inner {
        border-radius: 4px;
        background: transparent;
        box-shadow: none;
        &-content {
          padding: .25rem .25rem;
        }
      }
      &.popoverPreviewHotspot {
        .ant-popover-inner {
          background: rgb(0 0 0 / 0.75);
          iframe {
            border: none!important;
            outline: none!important;
          }
        }
      }
    }


    .popoverControlbar {
      .ant-popover-inner-content {
        padding: .5rem .5rem;
        background: #000;
        border-radius: 8px;
      }
    }


    .popoverSceneList {
      .ant-popover-inner {
        background: transparent;
        box-shadow: none;
        &-content {
          padding: 0;
        }
      }
      .dropdownList {
        width: 200px;
        .--listing {
          width: 100%;
        }
        button {
          position: relative;
          cursor: pointer;
          /* text-transform: uppercase; */
          background: none;
          border-radius: 0;
          color: var(--color-text);
          border: 1px solid var(--color-primary);
          border-bottom: none;
          padding: 0.5rem 1rem;
          width: 100%;
          background: var(--color-primary);
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          flex: 0 0 auto;
          transform: translateY(0.5rem);
          &.active {
            background: #fff;
            color: var(--color-primary);
            border-color: transparent;
            box-shadow: 0 12px 24px rgb(0 0 0 / 0.15);
          }
          &:last-child {
            border: 1px solid var(--color-primary);
          }
          .--menuSceneTitle {
            position: relative;
            z-index: 1;
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
          }
        }
      }
    }

    .ant-badge-count {
      z-index: 3;
    }

    .fancybox__container {
      z-index: 1055 !important;
    }

    .ant-modal {
      padding-bottom: 0;
      border: none !important;
      /* min-height: 200px; */
      display: inline-flex !important;
      /* overflow: hidden; */
      &-close {
        display: none;
      }
      &-body {
        /* max-height: 85vh; */
        padding: 0;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        margin: 0 auto;
        border-radius: 12px;
        overflow: hidden;
        /* div {
          width: 100%;
          height: 100%;
        } */
      }
      &-mask {
        background-color: rgba(0, 0, 0, 0.75);
      }
      &-content {
        width: 100%;
        /* height: 100%; */
        background-color: transparent;
      }
    }

    .ant-btn > i, .ant-btn > span {
      display: inline-flex !important;
    }

    img {
      width: 100%;
      height: auto;
    }

    iframe {
      /* width: 100% !important; */
      height: auto;
    }
    p {
      margin: unset;
    }

    .se-component {
      display: inline-block;
    }

    .se-image-container {
      display: block;
    }
  }

  .tree-scene__collapse {
    padding-bottom: .5rem;
    border-bottom: 1px solid rgb(255 255 255 / .15);
    .ant-collapse {
      &-content {
      }
      &-content-box {
        padding-left: 0!important;
        padding-right: 0!important;
      }
    }
  }


  .inline-flex {
    display: inline-flex !important;
    flex: 0 0 auto;
  }

  .absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .hide-scrollbar {
    &::-webkit-scrollbar {
      /* This is the magic bit for WebKit */
      display: none;
    }
  }

  .el-hide {
    display: none !important;
  }

  .hotspotTitle {
    em {
      color: #fff;
      display: block;
      text-align: center;
      margin: 0 auto;
      font-style: initial;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
    }
  }

  .slideModal {
    margin: 0 !important;
    padding: 0 !important;
    max-width: 100% !important;
    .ant-modal-content, .ant-modal-body{
      height: 100%;
      background: rgba(0, 0, 0, .25);
      /* backdrop-filter: blur(4px); */
    }
  }

  .flickity {
    &-button {
      svg {
        width: 40%!important;
        top: 50%!important;
        left: 50%!important;
        transform: translate(-50%, -50%)!important;
      }
    }
  }

  .shopify-buy-frame.is-sticky {
    right: 0 !important;
    top: 20% !important;
    iframe {
      width: 46px !important;
    }
  }

  .gmnoprint {
    display:none;
  }

  .ant-select {
    &-dropdown {
      border-radius: 4px!important;
      min-width: 140px!important;
      background: var(--color-dark)!important;
      border: 1px solid rgb(255 255 255 / .25)!important;
      .ant-empty-description {
        color: #fff;
      }
    }
    &-item {
      margin: 0!important;
      padding: .5rem!important;
      /* border-radius: 6px!important; */
      color: #fff!important;
      &-option-active {
        background-color: var(--color-dark-2)!important;
      }
      &-option-selected {
        background-color: var(--color-theme)!important;
      }
    }
    .ant-select-clear {
      color: var(--color-smoke);
      right: 26px;
      margin-top: -8px;
    }
    .ant-select-arrow {
      color: var(--color-smoke);
    }
  }

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .link {
    text-decoration: none;
    color: #000;
    cursor: pointer;
  }

  article {
    img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
    a {
      text-decoration: none;
      color: #000;
      transition: all 0.13s ease;
      &:hover {
        color: var(--color-1);
      }
    }
  }

  .dropdown-container {
    position: relative;
    display: inline-block;
    .dropdown-header {
      padding: 10px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .dropdown-list {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #fff;
      // border: 1px solid #00000045;
      border-radius: 8px;
      overflow: hidden;
    }
    .dropdown-item {
      padding: 10px;
      cursor: pointer;
      &:hover{
        background-color: #f0f0f0;
      }
    }
  }

  .react-select-container{
    .react-select__indicator-separator{
      display: none;
    }
    .react-select__control{
      height: 40px;
      padding: 0 10px;
    }
    .react-select__input{
      opacity: 0;
    }
    .react-select__indicator{
      padding: 0;
    }
    .react-select__input-container {
      opacity: 0;
    }
    .react-select__control{
      border: 1px solid #e8e8e8  !important;
      &:focus,&:focus-visible{
        border: 1px solid var(--color-1) !important;
      }
    }
    .react-select__value-container{
      height: 100%;
      padding: 0;
      display: flex;
      flex-wrap: unset;
    }
  }

  .pac-container {
    z-index: 30000;
  }

  .infoAddress {
    transform: none;
    transition: all 0.5s ease;
    &.active {
      transform: translate(calc(-100% - 40px), 0);
    }
    .--title {
      font-size: 22px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 4px;
    }
    .--view {
      color: var(--color-1);
      font-size: inherit;
      padding-bottom: 0.8rem;
      border-bottom: 1px solid rgba(255,255,255,0.2);
    }

    .--des {
      // max-height: calc(100vh - 480px);
      overflow-y: auto;
      flex: 1;
      width: calc(100% + 8px);
      padding: 1rem 8px 1rem 0;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #000;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--color-smoke);
      }
    }
    .--viewall {
      cursor: pointer;
      font-weight: 500;
      &:hover {
        color: var(--color-1);
      }
    }
    .interact {
      margin-top: 20px;
    }
    .--number {
      margin: 1rem 0 0.5rem;
      font-weight: 500;
    }
    .--listLocation {
      // margin-top: 8px;
      .--item {
        height: max-content;
        width: 100%;
        display: flex;
        .--imgLct{
          height: 95px;
        }
      }
      .slick-slide{
        margin-right: 10px;
        width: 145px;
        >div{
          height: max-content;
        }
      }
      .--title {
        position: absolute;
        bottom: 10px;
      }
    }
    .slick-dots {
      list-style: none;
      padding-left: 0;
      display: flex !important;
      gap: 12px;
      margin: 16px auto 0;
      width: max-content;
      li {
        width: 8px;
        height: 8px;
        background: rgba(168, 168, 168, 0.33);
        border-radius: 50%;
        overflow: hidden;
        transition: all 0.13s ease;
        &.slick-active {
          background: #9ca3af;
          width: 26px;
          border-radius: 50px;
        }
      }
      button {
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .interact {
    display: flex;
    gap: 8px;
    margin-top: 0.8rem;
    .--item {
      background: rgba(255,255,255,0.2);
      color: #fff;
      border-radius: 25px;
      padding: 8px 16px;
      display: flex;
      gap: 8px;
      line-height: 1;
      cursor: pointer;
      align-items: center;
      transition: var(--transition-regular);
      &:hover {
        background: var(--color-1);
        color: #fff;
      }
    }
  }

  .itemCmt {
    display: flex;
    gap: 8px;
    .--avatarCmt {
      width: 2.5rem;
      height: 2.5rem;
      flex: 0 0 auto;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .--infoCmt{
      flex: 1;
    }
    .--listImgCmt{
      margin-top: 6px;
      &.imgs_1 {
        .--img {
          width: 100%;
          height: 120px;
        }
      }
      &.imgs_2 {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 8px;
        .--img{
          height: 80px;
        }
      }
      &.imgs_3 {
        display: grid;
        grid-template-columns: calc(70% - 10px) 30%;
        grid-gap: 10px;
        grid-template-rows: auto auto;
        .--img{
          &:first-child{
            grid-column: 1;
            height: 130px;
          }
          &:nth-child(2),&:last-child{
            grid-column: 2;
            height: 60px;
          }
          &:last-child{
            margin-top: -70px;
            position: relative;

          }
          img{
            width: 100%;
          }
        }
        &.moreImg{
          .--img{
            position: relative;
           &:last-child{
            &::after{
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: #000;
              opacity: .5;
            }
            span{
              position: absolute;
              top: 50%;
              left: 50%;
              color: #FFF;
              z-index: 1;
              transform: translate(-50%,-50%);
            }
           }
          }
        }
      }
      .--img{
        border-radius: 6px;
        overflow: hidden;
        cursor: pointer;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .--contentCmt {
      background: #111;
      border: 1px solid rgba(255,255,255,0.2);
      padding: 0.5rem 1rem;
      border-radius: 0 1.25rem 1.25rem;
      position: relative;
    }
    .--desCmt {
      font-size: 13px;
    }
    .--focusComment {
      align-items: center;
      gap: 8px;
      font-size: 0.9rem;
      color: rgba(255,255,255,0.5);
      border-radius: 25px;
      padding: 4px 12px 4px 8px;
      display: inline-flex;
      cursor: pointer;
      transition: var(--transition-regular);
      position: absolute;
      z-index: 10;
      background: #292929;
      bottom: -52px;
      left: 10px;
      width: max-content;
      opacity: 0;
      pointer-events: none;
    }
    .--interactCmt {
      font-size: 0.9rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .--left{
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .--right {
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .--item {
        cursor: pointer;
        padding: 4px 8px;
        border-radius: 100px;
        display: flex;
        gap: 6px;
        align-items: center;
        &.like{
          background: var(--color-smoke);
          position: relative;
          top: -12px;
          left: 12px;
          &.active{
            background: rgb(253 78 93);
            i{
            }
          }
        }
      }
    }
    .--topCmt {
      justify-content: space-between;
      align-items: center;
      display: flex;
    }
    .--nameCmt {
      font-weight: 500;
      color: var(--color-smoke);
    }
    .--timeCmt {
      color: #888;
      font-weight: initial;
    }
    &:hover {
      .--focusComment {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }

  .cards-fly {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.25);
    padding: 0.5rem 1rem;

    .cards-header {
      display: flex;
      gap: 40px;
      align-items: center;
      font-size: 14px;
      border-bottom: 1px solid #e8e8e8;
      padding-bottom: 4px;
      margin-bottom: 8px;
      .--title {
        display: flex;
        gap: 8px;
        align-items: center;
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 180px;
          width: 100%;
        }
      }
      i {
        color: #9ca3af;
      }
    }
    .cards-body {
      display: flex;
      gap: 8px;
      .--avatar {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        overflow: hidden;
        flex: 0 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .--name {
        font-weight: 600;
      }
      .--viewtime {
        display: flex;
        font-size: 0.9rem;
        gap: 6px;
        opacity: 0.75;
      }
    }
  }

  .itemLocation {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    .--imgLct {
      width: 100%;
      height: 100%;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.5;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .--totalImg {
      border-radius: 6px;
      background: rgba(0,0,0,0.2);
      position: absolute;
      top: 4px;
      left: 4px;
      font-size: 12px;
      color: #fff;
      padding: 0 8px;
      display: flex;
      gap: 4px;
      align-items: center;
      height: 24px;
      text-shadow: 0 1px 8px #000;
    }
    .--viewLct {
      display: flex;
      align-items: center;
      font-size: 12px;
      gap: 4px;
      // margin-top: 4px;
      span {
        opacity: 0.8;
      }
    }
    .--titleLct {
      position: absolute;
      bottom: 0;
      padding: 0.8rem 0.8rem 0.4rem;
      left: 0;
      width: 100%;
      color: #fff;
      .--nameLct {
        font-size: 14px;
        color: #FFF;
      }
    }
  }
  .PopupMarker {
    width: 220px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 6px 24px rgba(0,0,0,0.3);
    overflow: hidden;
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom:100%;
    transform: translate(-50%,0);
    .--close{
      position: absolute;
      top: 4px;
      right: 4px;
      width: 24px;
      height: 24px;
      font-size: 1.1rem;
      text-shadow: 0 1px 8px #000;
      background: rgba(0,0,0,0.2);
      border-radius: 6px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
    .--img {
      width: 100%;
      position: relative;
      height: 130px;
      display: block;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .--totalImg {
        border-radius: 6px;
        background: rgba(0,0,0,0.2);
        position: absolute;
        top: 4px;
        left: 4px;
        font-size: 12px;
        color: #fff;
        padding: 0 8px;
        display: flex;
        gap: 4px;
        align-items: center;
        height: 24px;
        text-shadow: 0 1px 8px #000;
      }
      .--icon {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: calc(100% - 20px);
        padding: 8px;
        background: var(--color-1);
        border-radius: 100%;
        border: 3px solid #fff;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 100%;
          height: auto;
        }
      }
    }
    .--txt {
      padding: 2rem 1rem 1rem;
    }
    .--view360 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      transition: var(--transition-regular);
      font-size: 12px;
      padding: 8px 16px 6px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 2px solid #fff;
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 1px;
      border-radius: 10px;
      background: rgba(255,255,255,0.25);
      backdrop-filter: blur(5px);
      &:hover {
        background: var(--color-1);
        border-color: transparent;
      }
    }
    .--name {
      font-size: 14px;
      text-align: center;
    }
    .--interact {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      .--right {
      }
      .--item {
        display: flex;
        gap: 4px;
        align-items: center;
        background: #f3f4f6;
        padding: 4px 10px;
        border-radius: 25px;
        font-size: 0.9rem;
      }
    }
  }
  .gm-style{
    >div{
      display: flex;
    }
  }
  .Marker {
    position: relative;
    display: flex;
    align-items: center;
    width: max-content;
    transform: translateZ(0) translate(-50%, -50%);
    backface-visibility: hidden;
  }

  .addInfo {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
    cursor: pointer;
    img {
      width: 70px;
      height: auto;
    }
    @media(max-width:640px){
      img{
        width: 48px;
      }
    }
  }


  .btn{
    &.btnHover {
      position: relative;
      overflow: hidden;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
        background: #e8e8e861;
        opacity: 0;
        transition: all 0.13s ease;
      }
      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }
    img,span{
      position: relative;
      z-index: 1;
    }
  }

  input,
  textarea,
  select {
    &:focus-visible,
    &:focus {
      outline: none;
      border-color: var(--color-1) !important;
    }
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    .form-group {
      width: 100% !important;
      &.w-50 {
        width: calc(50% - 4px) !important;
      }
      &.radio {
        display: flex;
        gap: 16px;
        input[type='radio']:checked + .radio-label:before {
          background-color: var(--color-1);
          box-shadow: inset 0 0 0 4px #f4f4f4;
        }
        input[type='radio'] {
          position: absolute;
          opacity: 0;
          width: 20px;
          height: 20px;
        }
        input[type='radio'] + .radio-label:before {
          content: '';
          background: #f4f4f4;
          border-radius: 100%;
          border: 1px solid var(--color-1);
          display: inline-block;
          width: 20px;
          height: 20px;
          position: relative;
          margin-right: 6px;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
        .item {
          display: flex;
          align-items: center;
        }
        label {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
      &.addimg {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        flex-flow: column;
        .--listimg {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }
        .--img {
          width: 80px;
          height: 80px;
          position: relative;
          border-radius: 6px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 6px;
          }
        }
        .--delete {
          position: absolute;
          width: 20px;
          height: 20px;
          border: 2px solid #fff;
          background: #e3e3e3;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          top: -10px;
          right: -10px;
          font-size: 12px;
          cursor: pointer;
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          font-size: 0.9rem;
          font-weight: 600;
          gap: 6px;
          border: none;
          outline: none;
          padding: 9px 1rem 6px;
          background: #efefef;
        }
      }
    }
    input {
      width: 100%;
      height: 40px;
      padding: 0 10px;
      border-radius: 6px;
      border: 2px solid #e8e8e8;
      transition: var(--transition-regular);
      &:focus {
        border-color: var(--color-1);
      }
    }
    select {
      width: 100%;
      height: 40px;
      padding: 0 10px;
      border-radius: 6px;
      border: 2px solid #e8e8e8;
    }
    textarea {
      border: 2px solid #e8e8e8;
      border-radius: 6px;
      height: 80px;
      padding: 12px;
      width: 100%;
      transition: var(--transition-regular);
      &:focus {
        border-color: var(--color-1);
      }
    }
  }

  .react-select-container {
    .react-select__control {
      border: 2px solid #e8e8e8;
      transition: var(--transition-regular);
      &.react-select__control-is-focused {
        border-color: var(--color-1);
      }
    }
  }

  .modal {
    .close {
      position: absolute;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      box-shadow: 0px 30px 100px 0px rgba(0, 0, 0, 0.04);
      border: 1px solid rgba(0, 0, 0, 0.16);
      border-radius: 50%;
      top: -22px;
      right: -22px;
      background: #fff;
      cursor: pointer;
    }
  }
  .showCardInfo {
    position: fixed;
    display: flex;
    transform: translate(38px, 0) rotate(-90deg);
    right: -38px;
    top: 106px;
    border-radius: 6px 6px 0 0;
    background: rgba(0, 0, 0, 0.2);
    gap: 6px;
    color: #FFF;
    padding: 8px 16px;
    z-index: 9;
    align-items: center;
    transition: all .3s ease;
    cursor: pointer;
    &.active {
      transform: rotate(-90deg);
    }
    @media(max-width:640px){
      height: 46px;
      top: initial;
      bottom: 100px;
      background: rgba(0,0,0,0.5);
      border-radius: 12px;
    }
  }

  .modal-addComment {
    .modal-content {
      .form {
        gap: 0;
        border: none;
        margin: 0;
        padding: 12px 0;
        .form-group.addimg {
          gap: 0;
        }
      }
    }
    .--user{
      margin-bottom: 8px;
      .--avatar{
        width: 36px;
    height: 36px;
      }
      .--name {
        font-weight: 500;
      }

    }
  }
  .modal-Login{
    text-align: center;
    .modal-dialog{
      width: 90%;
      max-width: 460px;
      margin: 0 auto;
    }
    .modal-content{
      padding: 2.5rem 3rem;
      border-radius: 20px;
    }
    .--formlogin{
      display: flex;
      align-items: center;
      color: #9F9F9F;
      gap: 8px;
      margin-bottom: 20px;
      display: none;
      .--line{
        flex: 1;
        border-bottom: 1px solid #E6E6E6 ;
      }
    }
    .--btn {
      display: flex;
      gap: 1rem;
      justify-content: center;
      .btn{
        max-width: 40%;
        height: 3rem;
        display: flex;
        align-items: center;
        flex: 1;
        padding: 0 0.5rem;
        justify-content: center;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition: var(--transition-smooth);
        img{
          height: 20px;
        }
        &:hover {
          transform: scale(0.95);
        }
        &.btn-facebook{
          background: #3975EA;
        }
        &.btn-google{
          background: #FFF;
        }
        &:after {
          display: none;
        }
      }
    }
    .--logo{
      margin-bottom: 2rem;
      img{
        width: 180px;
        margin: 0 auto;
        height: auto;
      }
    }
    .--name{
      font-size: 2rem;
      font-weight: 600;
    }
    .--note{
      font-size: 0.9rem;
      margin-bottom: 2rem;
      color: #828282;
    }
  }
  .modal-faq {
    .close {
      position: absolute;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      border-radius: 8px;
      top: 4px;
      right: 4px;
      background: rgba(255,255,255,0.5);
      outline: none;
      border: none;
      transition: var(--transition-regular);
      &:hover {
        background: #f0f0f0;
      }
    }
    .modal-content {
      padding: 2rem;
    }
    .--title {
      font-weight: 600;
      margin-bottom: 1.5rem;
    }

    .accordion {
      max-height: 400px;
      overflow-y: auto;
      width: calc(100% + 20px);
      padding-right: 20px;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
    }
    .accordion-item {
      border: none;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #d9d9d9;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
    .accordion-button {
      color: #000;
      background: none;
      padding: 0;
      display: flex;
      gap: 32px;
      box-shadow: none;
      align-items: flex-start;
      justify-content: space-between;
      .--question {
        color: var(--color-1);
        margin-bottom: 0;
        font-size: 1.1rem;
        line-height: 140%;
      }
      i {
        margin-top: 4px;
        color: var(--color-1);
        &.fa-plus {
          display: none;
        }
      }
      &.collapsed {
        &:hover {
          .--question {
            color: var(--color-1);
          }
          i {
            color: var(--color-1);
          }
        }
        .--question {
          color: #000;
        }
        i {
          color: #000;
          &.fa-plus {
            display: block;
          }
          &.fa-minus {
            display: none;
          }
        }
      }
      &::after {
        display: none;
      }
      &:focus {
        outline: none;
        box-shadow: none;
        border-color: unset;
      }
    }
    .accordion-body {
      padding: 1rem 0 0;
      article {
        color: #6c757d;
      }
    }
  }
  .modal-instruct{
    .modal-content{
      padding: 0.5rem 0.5rem 1rem 0.5rem;
    }
    .close {
      width: 2rem;
      height: 2rem;
      font-size: 1rem;
      border: 2px solid;
      box-shadow: 0 2px 16px #333;
      top: -1rem;
      right: -1rem;
      z-index: 10;
      background: #333;
      color: #fff;
    }
    .modal-dialog {
      width: 90vw;
      max-width: 1000px;
    }
    .slick-dots{
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      display: flex !important;
      margin-top: 24px;
      align-items: center;
      justify-content: center;
      gap: 8px;
      li{
        &.slick-active{
          button{
            background: var(--color-1);
            color: #FFF;
          }
        }
      }
      button{
        background: #f3f3f3;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        width: 32px;
        height: 32px;
        color: #000;
        border-radius: 50%;
      }
    }
  }


  .drop-address {
    width: 300px;
    padding: 0;
    transform: translateY(0.5rem);
    box-shadow: 0 12px 40px rgba(0,0,0,0.3);
    border-radius: 12px!important;
    .--search {
      padding: 1rem;
      background: #f3f4f6;
      .--title {
        font-weight: 600;
        font-size: 1.1rem;
      }
      input {
        padding-left: 2.5rem;
        border-radius: 10px;
        border: 2px solid #e8e8e8;
        &:focus {
          border-color: var(--color-1);
        }
      }
      .form-group{
        position: relative;
        i{
          position: absolute;
          top: 50%;
          transform: translate(0,-50%);
          left: 12px;
        }
        input{
          padding-left: 32px;
        }
      }
    }
    .--listAddress {
      overflow-y: auto;
      max-height: 450px;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: #eee;
        border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 8px;
      }
      .itemAdr {
        padding: 0.8rem 1rem;
        transition: all 0.13s ease;
        cursor: pointer;
        &:hover {
          background: #f3f4f6;
        }
      }
    }
  }

  .itemAdr {
    display: flex;
    gap: 16px;
    align-items: center;
    .--imgAdr {
      width: 64px;
      height: 48px;
      flex: 0 0 auto;
      border-radius: 6px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .--nameAdr {
      margin-bottom: 2px;
    }
    .--numberAdr {
      color: #8C8C8C;
      font-size: 0.9rem;
    }
  }
`;

export const Flex = css`
  display: flex;
`;

export const FlexCol = css`
  display: flex;
  flex-direction: column;
`;

export const FlexRowCenter = css`
  ${Flex};
  justify-content: center;
  align-items: center;
`;

export const FlexRowStart = css`
  ${Flex};
  justify-content: flex-start;
  align-items: center;
`;

export const FlexRowEnd = css`
  ${Flex};
  justify-content: flex-end;
  align-items: center;
`;

export const FlexRowBetween = css`
  ${Flex}
  justify-content: space-between;
  align-items: center;
`;

export const FlexColCenter = css`
  ${FlexCol};
  ${FlexRowCenter}
`;

export const FlexColStart = css`
  ${FlexCol};
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FlexColEnd = css`
  ${FlexCol};
  justify-content: flex-end;
  align-items: flex-end;
`;

export const FlexColBetween = css`
  ${FlexCol};
  justify-content: space-between;
  align-items: baseline;
`;

export const BackgroundCover = css<{ bgUrl: string | null | undefined }>`
  background-image: url(${props => props.bgUrl && props.bgUrl});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const BackgroundContain = css`
  ${BackgroundCover};
  background-size: contain;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${FlexRowCenter};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1;
`;
