/* eslint-disable prettier/prettier */
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import {
  getLayoutApi,
  getTransApi,
  getFaqApi,
  getListPlaceCategoryApi,
  getListLocationApi,
  getProfileApi,
  loginApi,
} from 'service/masterDataApi';
import {
  LOGIN,
  GET_ME,
  actions,
  GET_APP_INFO,
  GET_LIST_LOCATION,
  GET_LIST_PLACE_CATEGORY,
} from './slice';
import { isServer } from '@utils/helper';
import { KEY_COOKIE } from '@type/customType';
import { selectProvinceSelected } from './selectors';

export function* watchAndLog() {
  while (true) {
    try {
      const styleConsole =
        'background: yellow; font-weight: bold; color: #40a9ff8c;';
      const action = yield take('*');
      const state = yield select();
      if (action.type !== '@@router/LOCATION_CHANGE') {
        console.group(action.type);
        console.log('%cBefore State', styleConsole, state);
        console.info('%cDispatching', styleConsole, action);
        console.log('%cNext State', styleConsole, state);
        console.groupEnd();
      }
    } catch {}
  }
}

export function* getAppInfo({ payload }: any) {
  try {
    const response = yield all([
      call(getLayoutApi, payload),
      call(getTransApi, payload),
      call(getFaqApi, payload),
    ]);
    yield put(
      GET_APP_INFO.success({
        layout: response[0]?.data,
        trans: response[1]?.data,
        faq: response[2]?.data?.data,
      }),
    );
  } catch (err) {
    console.log(err);
    yield put(GET_APP_INFO.failure());
  }
}

export function* getListPlaceCategory({ payload }: any) {
  try {
    const response = yield call(getListPlaceCategoryApi, payload);
    yield put(GET_LIST_PLACE_CATEGORY.success(response.data.data));
  } catch (err) {
    console.log(err);
    yield put(GET_LIST_PLACE_CATEGORY.failure());
  }
}

export function* getListLocation({ payload }: any) {
  try {
    const province = yield select(selectProvinceSelected);
    const response = yield call(getListLocationApi, {
      ...payload,
      provinceId: province,
    });
    yield put(GET_LIST_LOCATION.success(response.data.data));
  } catch (err) {
    console.log(err);
    yield put(GET_LIST_LOCATION.failure());
  }
}

export function* login({ payload }: any) {
  try {
    const { data } = yield call(loginApi, payload);
    if (data.data.token)
      document.cookie = `${KEY_COOKIE.TOKEN}=${data.data.token}; path=/; SameSite=None; Secure`;
    yield put(LOGIN.success());
  } catch (err) {
    yield put(LOGIN.failure());
  }
}

export function* getMe({ payload }: any) {
  try {
    const { data } = yield call(getProfileApi, payload);
    yield put(GET_ME.success(data.data));
  } catch {
    yield put(GET_ME.failure());
  }
}

function* watchIsLogged() {
  try {
    while (true) {
      yield take(LOGIN.SUCCESS);
      yield put(actions.changeIsLogged({ isLogged: true }));
    }
  } catch (err) {
    console.log(err);
  }
}

const appSagas: any = [
  fork(watchIsLogged),
  takeLatest(LOGIN.TRIGGER, login),
  takeLatest(GET_ME.TRIGGER, getMe),
  takeLatest(GET_APP_INFO.TRIGGER, getAppInfo),
  takeLatest(GET_LIST_PLACE_CATEGORY.TRIGGER, getListPlaceCategory),
  takeLatest(GET_LIST_LOCATION.TRIGGER, getListLocation),
];

if (!isServer && process.env.NODE_ENV === 'development')
  appSagas.push(fork(watchAndLog));

export default appSagas;
