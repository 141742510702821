import {
  selectIsLogged,
  selectIsShowDialogLogin,
} from '@containers/App/selectors';
import { actions } from '@containers/App/slice';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useValidatePermission = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector(selectIsLogged);
  const isShowDialogLogin = useSelector(selectIsShowDialogLogin);

  const onShowDialogLogin = useCallback(() => {
    !isShowDialogLogin && dispatch(actions.changeIsShowDialogLogin(true));
  }, [dispatch, isShowDialogLogin]);

  const onCloseDialogLogin = useCallback(() => {
    isShowDialogLogin && dispatch(actions.changeIsShowDialogLogin(false));
  }, [dispatch, isShowDialogLogin]);

  const onValidatePermission = useCallback(
    async (cb: Function) => {
      if (isLogged) await cb();
      else onShowDialogLogin();
    },
    [isLogged, onShowDialogLogin],
  );

  return useMemo(
    () => ({
      isShowDialogLogin,
      onShowDialogLogin,
      onCloseDialogLogin,
      onValidatePermission,
    }),
    [
      isShowDialogLogin,
      onCloseDialogLogin,
      onShowDialogLogin,
      onValidatePermission,
    ],
  );
};
