/* eslint-disable no-nested-ternary */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@type/index';
import {
  CONFIG_CALL_TO_ACTION_DEFAULT,
  CONFIG_FORM_CAPTURE_DEFAULT,
} from './constants';

import { initialState } from './slice';
import {
  onReturnListSceneIdToOpenFormCapture,
  onReturnListHotspotIdToOpenFormCapture,
  onReturnListHotspotTypeToOpenFormCapture,
  onReturnListFormCaptureConfigCorrespondingToListSceneId,
  onReturnListFormCaptureConfigCorrespondingToListHotspotId,
  onReturnListFormCaptureConfigCorrespondingToListHotspotType,
} from './utils';

const selectApp = (state: RootState) => state.app;
const selectDomain = (state: RootState) => state.home || initialState;

export const selectHome = createSelector(
  [selectDomain],
  dashboardState => dashboardState,
);

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.loading,
);

export const selectIsError = createSelector(
  [selectDomain],
  state => state.error,
);

export const selectIsSuccess = createSelector(
  [selectDomain],
  state => state.success,
);

export const selectToken = createSelector([selectDomain], state => state.token);

/**
 * SECTION: DETAIL PROJECT SELECTORS
 */

export const selectDetailProject = createSelector(
  [selectDomain],
  state => state.project,
);

export const selectTitleProject = createSelector(
  [selectDetailProject],
  project => project?.title || undefined,
);

export const selectFirstSceneMediaProject = createSelector(
  [selectDetailProject],
  project => project?.first_scene?.media || undefined,
);

export const selectThumbnailProject = createSelector(
  [selectDetailProject],
  project => project?.thumbnail || undefined,
);

export const selectFeatureImageProject = createSelector(
  [selectDetailProject],
  project => project?.featured_image || undefined,
);

/**
 * !SECTION
 */

/**
 * SECTION: CONFIG SETTING GLOBAL (DESIGN TAB) SELECTORS
 */

export const selectHotspotConfigGlobal = createSelector(
  [selectDetailProject],
  project => project?.config?.design?.hotspotConfig || undefined,
);

export const selectPolygonConfigGlobal = createSelector(
  [selectDetailProject],
  project => project?.config?.design?.polygonConfig || undefined,
);

export const selectThemeConfigGlobal = createSelector(
  [selectDetailProject],
  project => project?.config?.design?.themeConfig || undefined,
);

export const selectPopupConfigGlobal = createSelector(
  [selectDetailProject],
  project => project?.config?.design?.popupConfig || undefined,
);

export const selectFormCaptureConfigGlobal = createSelector(
  [selectDetailProject],
  project => ({
    ...CONFIG_FORM_CAPTURE_DEFAULT,
    ...project?.config?.design?.formCaptureConfig,
  }),
);

export const selectCallToActionConfigGlobal = createSelector(
  [selectDetailProject],
  project => ({
    ...CONFIG_CALL_TO_ACTION_DEFAULT,
    ...project?.config?.design?.callToActionConfig,
  }),
);

/**
 * !SECTION
 */

/**
 * SECTION: CONFIG PROJECT SELECTORS (MARKETING / SETTING TAB)
 */

export const selectMetaDataProject = createSelector(
  [selectDetailProject],
  project => project?.config?.marketing?.meta_data || undefined,
);

export const selectGATrackingProject = createSelector(
  [selectDetailProject],
  project => project?.config?.marketing?.analytic?.uid || undefined,
);

export const selectGATagTrackingProject = createSelector(
  [selectDetailProject],
  project =>
    project?.config?.marketing?.analytic?.ggTag ||
    process.env.NEXT_PUBLIC_GA_TAG_DEFAULT ||
    undefined,
);

export const selectScriptChats = createSelector(
  [selectDetailProject],
  project => project?.config?.marketing?.script_chats || undefined,
);

export const selectConfigLittlePlanetProject = createSelector(
  [selectDetailProject],
  project => project?.config?.setting?.intro_little_planet || undefined,
);

export const selectConfigControlModeProject = createSelector(
  [selectDetailProject],
  project =>
    project?.config?.setting?.control_mode || ({ mode: 'drag' } as const),
);

export const selectConfigFollowModeProject = createSelector(
  [selectDetailProject],
  project => project?.config?.setting?.follow_mode || { enable: true },
);

export const selectConfigFirstSceneProject = createSelector(
  [selectDetailProject],
  project => project?.config?.setting?.general?.first_scene || undefined,
);

export const selectLanguageProject = createSelector(
  [selectDetailProject],
  project => project?.config?.setting?.general?.language || 'en',
);

/**
 * !SECTION
 */

/**
 * SECTION: CONFIG SELECTORS
 */

export const selectConfigAutoRotate = createSelector(
  [selectDomain],
  state => state.configAutoRotate || undefined,
);

export const selectConfigAutoChangeScene = createSelector(
  [selectDomain],
  state => state.configAutoChangeScene || undefined,
);

export const selectConfigSoundProject = createSelector(
  [selectDomain],
  state => state.configSoundProject || undefined,
);

export const selectIsFirstScene = createSelector(
  [selectDomain],
  state => state.isFirstScene,
);

/**
 * !SECTION
 */

/**
 * SECTION: DATA SELECTED/LIST SELECTORS
 */

export const selectListScene = createSelector(
  [selectDomain],
  state => state.listScene || [],
);

export const selectTotalView = createSelector([selectDomain], state =>
  state.listScene.reduce((a, b) => a + (b.view_count || 0), 0),
);

export const selectListGroup = createSelector([selectDomain], state =>
  state.listGroup.length === 1 && state.listGroup[0].id === 'uncategory'
    ? state.listGroup
    : state.listGroup.filter(item => item.id !== 'uncategory'),
);

export const selectListSceneByGroup = createSelector(
  [selectListGroup],
  listGroup =>
    listGroup
      .reduce((result, item) => {
        result.push(item.scenes);
        return result;
      }, [])
      .flat(),
);

export const selectListPostUsedOnTour = createSelector(
  [selectDomain],
  state => state.listPost || [],
);

export const selectSceneSelected = createSelector(
  [selectDomain],
  state => state.sceneSelected || undefined,
);

export const selectPrevSceneSelected = createSelector(
  [selectDomain],
  state => state.prevSceneSelected || undefined,
);

export const selectHotspotSelected = createSelector(
  [selectDomain],
  state => state.hotspotSelected || undefined,
);

export const selectListHotspotFlycam = createSelector(
  [selectDomain],
  state => state.listHotspotFlycam,
);

export const selectDetailFirstScene = createSelector(
  [selectDetailProject, selectListScene, selectListGroup],
  (project, listScene, listGroup) =>
    project?.config?.setting?.general?.first_scene
      ? listScene.filter(
          item => item.id === project?.config?.setting?.general?.first_scene,
        )[0] || listGroup[0].scenes[0]
      : listGroup.length
      ? listGroup[0].scenes[0]
      : undefined,
);

/**
 * !SECTION
 */

/**
 * SECTION: PLUGIN SELECTORS
 */

export const selectDataMap = createSelector(
  [selectDomain],
  state => state.dataMap || undefined,
);

export const selectConfigMap = createSelector(
  [selectDomain],
  state => state.configMap || undefined,
);

export const selectListFloorplan = createSelector(
  [selectDomain],
  state => state.listFloorplan || [],
);

export const selectConfigFloorplan = createSelector(
  [selectDomain],
  state => state.configFloorplan || undefined,
);

export const selectConfigCollaboration = createSelector(
  [selectDomain],
  state => state.configCollaboration || undefined,
);

export const selectConfigControlbar = createSelector(
  [selectDomain],
  state => state.configControlbar || undefined,
);

// SECTION: PLUGIN FORM CAPTURE

export const selectListFormCapture = createSelector(
  [selectDomain],
  state => state.listFormCapture || [],
);

export const selectConfigFormCapture = createSelector(
  [selectDomain],
  state => state.configFormCapture || [],
);

export const selectHotspotSelectedTemporary = createSelector(
  [selectDomain],
  state => state.hotspotSelectedTemporary || null,
);

export const selectIsVisibleFormCapture = createSelector(
  [selectDomain],
  state => state.isVisibleFormCapture,
);

export const selectFormCaptureConfigSelected = createSelector(
  [selectDomain],
  state => state.formCaptureConfigSelected || undefined,
);

export const selectFormCaptureSelected = createSelector(
  [selectListFormCapture, selectFormCaptureConfigSelected],
  (list, config) =>
    config && config.form
      ? list.filter(item => item.id === config?.form)[0] || undefined
      : undefined,
);

export const selectListSceneIdToOpenFormCapture = createSelector(
  [selectConfigFormCapture],
  config => onReturnListSceneIdToOpenFormCapture(config),
);

export const selectListFormCaptureConfigCorrespondingToListSceneId =
  createSelector([selectConfigFormCapture], config =>
    onReturnListFormCaptureConfigCorrespondingToListSceneId(config),
  );

export const selectListHotspotTypeToOpenFormCapture = createSelector(
  [selectConfigFormCapture],
  config => onReturnListHotspotTypeToOpenFormCapture(config),
);

export const selectListFormCaptureConfigCorrespondingToListHotspotType =
  createSelector([selectConfigFormCapture], config =>
    onReturnListFormCaptureConfigCorrespondingToListHotspotType(config),
  );

export const selectListHotspotIdToOpenFormCapture = createSelector(
  [selectListScene, selectConfigFormCapture],
  (listScene, config) =>
    onReturnListHotspotIdToOpenFormCapture(listScene, config),
);

export const selectListFormCaptureConfigCorrespondingToListHotspotId =
  createSelector(
    [selectListScene, selectConfigFormCapture],
    (listScene, config) =>
      onReturnListFormCaptureConfigCorrespondingToListHotspotId(
        listScene,
        config,
      ),
  );

// !SECTION: FORM CAPTURE

export const selectConfigLogo = createSelector(
  [selectDomain],
  state => state.configLogo || undefined,
);

export const selectConfigScreenshotCapture = createSelector(
  [selectDomain],
  state => state.configScreenshotCapture || undefined,
);

export const selectConfigNadir = createSelector(
  [selectDomain],
  state => state.configNadir || undefined,
);

export const selectConfigProtectPassword = createSelector(
  [selectDomain],
  state => state.configProtectPassword || undefined,
);

export const selectConfigCopyRight = createSelector(
  [selectDomain],
  state => state.configCopyRight || undefined,
);

export const selectConfigPopupIntro = createSelector(
  [selectDomain],
  state => state.configPopupIntro || undefined,
);

export const selectListCallToAction = createSelector(
  [selectDomain],
  state => state.listCallToAction || [],
);

/**
 * !SECTION
 */

// !SECTION: USED FOR COLLABORATION ONLY

export const selectInfoUser = createSelector(
  [selectDomain],
  state => state.infoUser || undefined,
);

export const selectListCommentSceneSelected = createSelector(
  [selectDomain],
  state => state.listCommentSceneSelected || [],
);

export const selectIsOwnerTour = createSelector(
  [selectDetailProject, selectInfoUser],
  (project, user) => project?.user === user?.id,
);

export const selectIsAllowToAccessOnCollaborationMode = createSelector(
  [selectDomain],
  state => state.isAllowToAccessOnCollaborationMode,
);
// !SECTION

// !SECTION: USED FOR COMMENT GOTEREST ONLY

export const selectIsSuccessLoadListCommentGoterest = createSelector(
  [selectDomain],
  state => state.isSuccessLoadListCommentGoterest,
);

export const selectListCommentGoterestSelected = createSelector(
  [selectDomain, selectApp],
  (state, app) =>
    state.listCommentGoterestSelected.map(item => ({
      ...item,
      liked: app.infoUser
        ? item.listUserIdLikes.indexOf(app.infoUser.id) !== -1
        : false,
    })) || [],
);

export const selectIsOnModeCreateCommentGoterest = createSelector(
  [selectDomain],
  state => state.isOnModeCreateCommentGoterest,
);
// !SECTION
