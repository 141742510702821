import { put, takeLatest, call } from 'redux-saga/effects';
import { GET_SPONSOR_PAGE } from './slice';
import { getSponsorApi } from 'service/masterDataApi';

export function* getSponsor({ payload }: any) {
  try {
    const response = yield call(getSponsorApi, payload);
    yield put(GET_SPONSOR_PAGE.success(response.data));
  } catch (err) {
    // console.log(err);
    yield put(GET_SPONSOR_PAGE.failure());
  }
}

const sponsorSagas: any = [takeLatest(GET_SPONSOR_PAGE.TRIGGER, getSponsor)];

export default sponsorSagas;
