import { I_CommentGoterest, I_Post } from '@containers/Home/types';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createRoutine } from 'redux-saga-routines';
import { filterHotspotVisible, latLngToAthAtv } from '@utils/helper';
import { ContainerState } from './typesIndex';
import {
  I_FormCaptureConfig,
  I_CommentScene,
  I_Hotspot,
  I_Project,
  I_Scene,
  I_User,
} from './types';
import { analyticsConfigPlugin, analyticsListSceneAndListGroup } from './utils';

// The initial state of the App container
export const initialState: ContainerState = {
  loading: false,
  success: false,
  error: false,

  dataMap: null,
  listFloorplan: [],
  listFormCapture: [],
  listCallToAction: [],

  configMap: null,
  configLogo: null,
  configNadir: null,
  configCopyRight: null,
  configFloorplan: null,
  configPopupIntro: null,
  configFormCapture: null,
  configCollaboration: null,
  configControlbar: null,
  configProtectPassword: null,
  configScreenshotCapture: null,

  listScene: [],
  listGroup: [],
  listPost: [],

  token: null,
  project: null,
  isFirstScene: null,

  configSoundProject: {
    on: null,
  },
  configAutoRotate: {
    on: false,
    enabled: false,
    speed: 2,
    waitTime: 2,
  },
  configAutoChangeScene: {
    on: false,
    enabled: false,
    waitTime: 15,
  },

  sceneSelected: null,

  hotspotSelected: null,
  prevSceneSelected: null,
  listHotspotFlycam: [],

  // USED FOR FORM CAPTURE ONLY
  isVisibleFormCapture: null,
  formCaptureConfigSelected: null,
  hotspotSelectedTemporary: null,

  // USED FOR COMMENT GOTEREST ONLY
  isSuccessLoadListCommentGoterest: false,
  isOnModeCreateCommentGoterest: false,
  listCommentGoterestSelected: [],

  // USED FOR COLLABORATION ONLY
  infoUser: null,
  listCommentSceneSelected: [],
  isAllowToAccessOnCollaborationMode: null,
};

export const GET_DETAIL_PROJECT = createRoutine('Home/getDetailProject');
export const GET_INFO_USER = createRoutine('Home/getInfoUser');
export const UPDATE_COUNT_VIEW_SCENE = createRoutine(
  'Home/updateCountViewScene',
);
export const GET_LIST_HOTSPOT_FLYCAM = createRoutine(
  'Home/getToursWithinRadius',
);
export const GET_LIST_COMMENT_GOTEREST = createRoutine(
  'Home/getListCommentGoterest',
);
export const CREATE_COMMENT_SCENE = createRoutine('Home/createCommentScene');
export const UPDATE_COMMENT_SCENE = createRoutine('Home/updateCommentScene');
export const DELELE_COMMENT_SCENE = createRoutine('Home/deleteCommentScene');

export const changeProjectAction = createAction<{ slug: string }>(
  'Home/onChangeProject',
);

const homeSlice = createSlice({
  name: 'home',
  initialState,

  reducers: {
    setSceneSelected(
      state: ContainerState,
      action: PayloadAction<I_Scene | null>,
    ) {
      if (action.payload)
        if (state.isFirstScene === null) state.isFirstScene = true;
        else state.isFirstScene = false;

      state.sceneSelected = {
        ...action.payload,
        hotspots:
          action.payload?.hotspots?.filter(item =>
            filterHotspotVisible(item),
          ) || [],
      };
    },

    setIsAllowToAccessOnCollaborationMode(
      state: ContainerState,
      action: PayloadAction<boolean>,
    ) {
      state.isAllowToAccessOnCollaborationMode = action.payload || false;
    },

    onChangeIsOnModeCreateCommentGoterest(
      state: ContainerState,
      action: PayloadAction<boolean>,
    ) {
      state.isOnModeCreateCommentGoterest = action.payload || false;
    },

    setListCommentGoterestSelected(
      state: ContainerState,
      action: PayloadAction<I_CommentGoterest[]>,
    ) {
      state.listCommentGoterestSelected = action.payload || [];
    },

    createCommentGoterest(
      state: ContainerState,
      action: PayloadAction<I_CommentGoterest>,
    ) {
      const commentScenes = [
        action.payload,
        ...state.listCommentGoterestSelected,
      ];
      state.listCommentGoterestSelected = commentScenes;
    },

    updateCommentGoterest(
      state: ContainerState,
      action: PayloadAction<I_CommentGoterest>,
    ) {
      const commentScenes = state.listCommentGoterestSelected.map(item =>
        item.id === action.payload.id ? action.payload : item,
      );
      state.listCommentGoterestSelected = commentScenes;
    },

    setListCommentSceneSelected(
      state: ContainerState,
      action: PayloadAction<I_CommentScene[]>,
    ) {
      state.listCommentSceneSelected = action.payload || [];
    },

    createCommentScene(
      state: ContainerState,
      action: PayloadAction<I_CommentScene>,
    ) {
      const commentScenes = [action.payload, ...state.listCommentSceneSelected];
      state.listCommentSceneSelected = commentScenes;
      state.listScene = state.listScene.map(item =>
        item.id === state.sceneSelected.id
          ? { ...state.sceneSelected, commentScenes }
          : item,
      );
    },

    updateCommentScene(
      state: ContainerState,
      action: PayloadAction<I_CommentScene>,
    ) {
      const commentScenes = state.listCommentSceneSelected.map(item =>
        item.id === action.payload.id ? action.payload : item,
      );
      state.listCommentSceneSelected = commentScenes;
      state.listScene = state.listScene.map(item =>
        item.id === state.sceneSelected.id
          ? {
              ...state.sceneSelected,
              commentScenes: state.listCommentSceneSelected,
            }
          : item,
      );
    },

    deleteCommentScene(
      state: ContainerState,
      action: PayloadAction<I_CommentScene>,
    ) {
      const commentScenes = state.listCommentSceneSelected.filter(
        item => item.id !== action.payload.id,
      );
      state.listCommentSceneSelected = commentScenes;
      state.listScene = state.listScene.map(item =>
        item.id === state.sceneSelected.id
          ? { ...state.sceneSelected, commentScenes }
          : item,
      );
    },

    setToken(state: ContainerState, action: PayloadAction<string | null>) {
      state.token = action.payload;
    },

    setConfigSoundProject(
      state: ContainerState,
      action: PayloadAction<Partial<ContainerState['configSoundProject']>>,
    ) {
      const { on } = action.payload;
      if (on !== undefined) state.configSoundProject.on = on;
    },

    setConfigAutoChangeScene(
      state: ContainerState,
      action: PayloadAction<Partial<ContainerState['configAutoChangeScene']>>,
    ) {
      const { enabled, waitTime, on } = action.payload;
      if (on !== undefined) state.configAutoChangeScene.on = on;
      if (enabled !== undefined) state.configAutoChangeScene.enabled = enabled;
      if (waitTime !== undefined)
        state.configAutoChangeScene.waitTime = waitTime;
    },

    setConfigAutoRotate(
      state: ContainerState,
      action: PayloadAction<Partial<ContainerState['configAutoRotate']>>,
    ) {
      const { on, enabled, waitTime, speed } = action.payload;
      if (on !== undefined) state.configAutoRotate.on = on;
      if (enabled !== undefined) state.configAutoRotate.enabled = enabled;
      if (waitTime !== undefined) state.configAutoRotate.waitTime = waitTime;
      if (speed !== undefined) state.configAutoRotate.speed = speed;
    },

    setprevSceneSelected(
      state: ContainerState,
      action: PayloadAction<I_Scene | null>,
    ) {
      state.prevSceneSelected = action.payload;
    },

    setHotspotSelected(
      state: ContainerState,
      action: PayloadAction<I_Hotspot | null>,
    ) {
      state.hotspotSelected = action.payload;
    },

    setHotspotSelectedTemporary(
      state: ContainerState,
      action: PayloadAction<I_Hotspot | null>,
    ) {
      state.hotspotSelectedTemporary = action.payload;
    },

    setIsVisibleFormCapture(state, action: PayloadAction<boolean>) {
      state.isVisibleFormCapture = action.payload;
      if (!action.payload) state.formCaptureConfigSelected = null;
    },

    setFormCaptureConfigSelelected(
      state,
      action: PayloadAction<I_FormCaptureConfig | null>,
    ) {
      state.formCaptureConfigSelected = action.payload || null;
    },

    setListPostUsedOnTour(state, action: PayloadAction<I_Post[]>) {
      state.listPost = action.payload || [];
    },

    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },

    resetPage(state) {
      Object.keys(state)
        .filter(
          item =>
            item !== 'listProject' &&
            item !== 'configSoundProject' &&
            item !== 'configAutoRotate' &&
            item !== 'configAutoChangeScene',
        )
        .forEach(key => {
          state[key] = initialState[key];
        });
    },
  },

  extraReducers: builder => {
    builder.addCase(
      HYDRATE as string,
      (_state: ContainerState, action: PayloadAction<any>) => ({
        ..._state,
        ...action.payload.home,
      }),
    );

    builder
      .addCase(
        GET_DETAIL_PROJECT.TRIGGER,
        (
          state,
          // action: PayloadAction<{ id: string }>,
        ) => {
          state.loading = true;
          state.success = false;
          state.error = false;
        },
      )
      .addCase(
        GET_DETAIL_PROJECT.SUCCESS,
        (state, action: PayloadAction<I_Project>) => {
          const project = action.payload;
          const { scenes = [], groups = [], plugins = [] } = project;

          const { listScene, listGroup } = analyticsListSceneAndListGroup(
            scenes.filter(item => item.media),
            groups,
          );

          state.project = project;
          state.listScene = listScene;
          state.listGroup = listGroup;

          // NOTE: Plugins
          const {
            dataMap,
            configMap,
            configLogo,
            configNadir,
            configPopupIntro,
            configProtectPassword,
            configCopyRight,
            listFloorplan,
            configFloorplan,
            listFormCapture,
            configFormCapture,
            configCollaboration,
            configControlbar,
            configScreenshotCapture,
            formCaptureConfigOnProject,
            listCallToAction,
          } = analyticsConfigPlugin(plugins);

          state.dataMap = dataMap;

          state.configMap = configMap;

          state.configLogo = configLogo;

          state.configNadir = configNadir;

          state.configPopupIntro = configPopupIntro;

          state.configProtectPassword = configProtectPassword;

          state.configCopyRight = configCopyRight;

          state.listFloorplan = listFloorplan;

          state.configFloorplan = configFloorplan;

          state.listFormCapture = listFormCapture;

          state.configFormCapture = configFormCapture;

          state.listCallToAction = listCallToAction;

          state.configCollaboration = configCollaboration;

          state.configControlbar = configControlbar;

          state.configScreenshotCapture = configScreenshotCapture;

          if (formCaptureConfigOnProject)
            state.formCaptureConfigSelected = formCaptureConfigOnProject;

          // NOTE: config globally
          state.configAutoChangeScene.on =
            project.config?.setting?.auto_change_scene?.enable || false;
          state.configAutoChangeScene.enabled =
            project.config?.setting?.auto_change_scene?.enable || false;
          state.configAutoChangeScene.waitTime =
            project.config?.setting?.auto_change_scene?.wait_time || 2;

          state.configAutoRotate.on =
            project.config?.setting?.auto_rotate?.enable || false;
          state.configAutoRotate.enabled =
            project.config?.setting?.auto_rotate?.enable || false;
          state.configAutoRotate.speed =
            project.config?.setting?.auto_rotate?.speed || 2;
          state.configAutoRotate.waitTime =
            project.config?.setting?.auto_rotate?.wait_time || 2;

          state.loading = false;
          state.success = true;
          state.error = false;
        },
      )
      .addCase(GET_DETAIL_PROJECT.FAILURE, state => {
        state.loading = false;
        state.success = false;
        state.error = true;
      });

    builder.addCase(
      GET_INFO_USER.SUCCESS,
      (state, action: PayloadAction<I_User>) => {
        state.infoUser = action.payload;
      },
    );

    builder.addCase(
      GET_LIST_HOTSPOT_FLYCAM.SUCCESS,
      (state, action: PayloadAction<I_Project[]>) => {
        state.listHotspotFlycam = action.payload.map(item => ({
          id: item.id,
          title: item.title,
          width: 0,
          height: 0,
          type: 'flycam' as const,
          ...latLngToAthAtv(
            state.sceneSelected.lat,
            state.sceneSelected.lng,
            item.lat,
            item.lng,
          ),
          config: {
            style: {
              type: 'callout',
              effect: 'normal',
              rotate: 0,
              opacity: 100,
              size: 3,
              callout: {
                type: 'text',
                src: item?.location?.locationImage?.thumbnail_640_jpg,
                // item.featured_image ||
                // item.thumbnail?.src ||
                // item.location?.locationImage?.thumbnail_640_jpg,
                text: item.location?.name || '',
                degrees: Math.floor(Math.random() * (360 - 0 + 1) + 0),
              },
            },
            flycam: item,
          },
        }));
      },
    );

    builder.addCase(
      UPDATE_COUNT_VIEW_SCENE.SUCCESS,
      (state, action: PayloadAction<{ id: string; view_count: number }>) => {
        state.listScene = state.listScene.map(item =>
          item.id === action.payload.id
            ? { ...item, view_count: action.payload.view_count }
            : item,
        );
        state.sceneSelected.view_count = action.payload.view_count;
      },
    );

    builder
      .addCase(GET_LIST_COMMENT_GOTEREST.TRIGGER, state => {
        state.isSuccessLoadListCommentGoterest = false;
      })
      .addCase(
        GET_LIST_COMMENT_GOTEREST.SUCCESS,
        (state, action: PayloadAction<I_CommentGoterest[]>) => {
          state.isSuccessLoadListCommentGoterest = true;
          state.listCommentGoterestSelected = action.payload;
        },
      )
      .addCase(GET_LIST_COMMENT_GOTEREST.FAILURE, state => {
        state.isSuccessLoadListCommentGoterest = false;
      });
  },
});

export const { actions, reducer, name: sliceKey } = homeSlice;

export default reducer;
