import { DefaultSeo } from 'next-seo';
import { NextWebVitalsMetric } from 'next/app';
import { GlobalStyle } from '@styles/global-styles';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { ResponsiveGlobal } from '@styles/responsive';
import { Provider } from 'react-redux';
// import { onDisabledReactDevtoolOnProduction } from '@utils/helper';
import { AppProps } from 'next/app';
import 'slick-carousel/slick/slick.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'antd/dist/antd.css';
// import '../../scripts/wdyr.js';

import { wrapper } from '../store';
import Layout from '@components/Layout';
import {
  LIST_FAVICON_DEFAULT,
  LIST_LINK_STYLE_SHEET,
} from '@containers/Home/constants';
import { FunctionComponent } from 'react';

const MyApp: FunctionComponent<AppProps> = ({
  Component,
  ...rest
}: AppProps) => {
  const router = useRouter();
  const { store, props } = wrapper.useWrappedStore(rest);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <DefaultSeo
        title="Goterest | Bản đồ số 360 Việt Nam"
        description="Nền tảng bản đồ số & thư viện 360 các địa điểm du lịch Việt Nam. Chia sẻ điểm đến, lịch trình & tour du lịch"
        openGraph={{
          type: 'website',
          locale: 'vi_VN',
          url: router.pathname,
          site_name: 'Goterest',
          title: 'Goterest | Bản đồ số 360 Việt Nam',
          description:
            'Nền tảng bản đồ số & thư viện 360 các địa điểm du lịch Việt Nam. Chia sẻ điểm đến, lịch trình & tour du lịch.',
        }}
        additionalLinkTags={[...LIST_FAVICON_DEFAULT, ...LIST_LINK_STYLE_SHEET]}
        additionalMetaTags={[
          {
            property: 'category',
            content: 'Virtual Tour Software',
          },
          {
            property: 'name',
            content: 'Goterest 360 Map',
          },
          {
            name: 'application-name',
            content: 'Goterest',
          },
        ]}
      />
      <Provider store={store}>
        <Layout>
          <Component {...props.pageProps} />
        </Layout>
      </Provider>
      <GlobalStyle />
      <ResponsiveGlobal />
    </>
  );
};

export function reportWebVitals({
  id,
  name,
  // startTime,
  value,
  label,
}: NextWebVitalsMetric) {
  if ((window as any).gtag)
    (window as any).gtag('send', 'event', {
      eventCategory:
        label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      eventAction: name,
      eventValue: Math.round(name === 'CLS' ? value / 1000 : value), // values must be integers
      eventLabel: id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate.
    });
}

export default MyApp;
