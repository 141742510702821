/**
 *
 * styled SvgContainer
 *
 */
import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  .--header {
    // position: absolute;
    // top: 0;
    display: flex;
    z-index: 30;
    align-items: center;
    justify-content: space-between;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 0 2rem;
    height: 56px;
    .--logo {
      img {
        width: 120px;
        height: auto;
        // max-height: 40px;
      }
    }
    .--address {
      .--selectadr {
        // height: 40px;
        background: rgb(117 171 69 / 10%);
        color: var(--color-1);
        border: none;
        border-radius: 5px;
        padding: 4px 8px;
        font-weight: 700;
        font-size: 0.8rem;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
    .dropdown {
      .dropdown-toggle::after {
        color: #000;
      }
      .dropdown-menu {
        padding: 8px 12px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
        width: max-content;
        .--logout {
          cursor: pointer;
        }
      }
      button {
        display: flex;
        background: #fff;
        border: none;
        align-items: center;
        background: #f3f4f6;
        border-radius: 25px;
        padding: 4px 12px 4px 4px;
      }
      .--user {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .--avatar {
        width: 2rem;
        height: 2rem;
        padding: 2px;
        border-radius: 50%;
        border: 1px solid #d1d1d1;
        overflow: hidden;
        background: #fff;
        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .--name {
        color: #000;
        font-size: 12px;
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 60px;
      }
    }
    .--menu {
      display: flex;
      align-items: center;
      .--item {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        transition: all 0.13s ease;
        &.active,
        &:hover {
          color: var(--color-1);
        }
      }
      .--btn-submitPano {
        color: #fff !important;
        background: var(--color-1);
        border-radius: 25px;
        padding: 0.5rem 0.8rem;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 36px;
        box-shadow: 0 2px 8px rgb(116 171 68 / 30%);
        transition: var(--transition-smooth);
        &:hover {
          transform: scale(0.95);
        }
      }
    }
    .--language {
      select {
        border-radius: 100px;
        border: 1px solid #e8e8e8;
        height: 40px;
        padding: 0 12px;
      }
    }
    .--listTab {
      display: flex;
      gap: 8px;
      .--item {
        display: flex;
        gap: 8px;
        // height: 40px;
        border-radius: 100px;
        border: 1px solid transparent;
        background: #f3f4f6;
        color: #333;
        display: flex;
        align-items: center;
        padding: 0.5rem 0.8rem;
        transition: all 0.13s ease;
        &:hover {
          background: #fff;
          border-color: #eee;
          color: #333;
        }
        &.active {
          background: #fff;
          color: var(--color-1);
          border-color: #eee;
          font-weight: 700;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
        }
      }
    }
    .header-left {
      display: flex;
      align-items: center;
      .--logo {
        margin-right: 1rem;
      }
      .--address {
        margin-right: 40px;
      }
    }
    .header-right {
      display: flex;
      gap: 8px;
      align-items: center;

      .btn-login {
        border-radius: 100px;
        background: #e8e8e8;
        padding: 8px 16px;
        transition: all 0.3s ease;
        &:hover {
          background: var(--color-1);
          color: #fff;
        }
      }
    }
    @media (max-width: 640px) {
      padding: 0.5rem 1rem;
      position: relative;
      height: 48px;
      .header-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        /* margin-bottom: 0.5rem; */
      }
      .--tab {
        font-size: 20px;
      }
      .--menu {
        position: fixed;
        flex-flow: column;
        background: #fff;
        border-radius: 6px;
        pointer-events: none;
        transition: all 0.5s ease;
        right: 1rem;
        padding: 12px;
        bottom: 72px;
        border-radius: 12px;
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
        min-width: 140px;
        transform: translateX(120%);
        .--item {
          width: 100%;
        }
        &.active {
          transform: none;
          pointer-events: auto;
        }
        .--btn-submitPano {
          padding: 0.3rem 0.5rem;
          height: auto;
        }
      }
      .--listTab {
        .--item {
          padding: 0.3rem 0.5rem;
        }
      }
      .header-bot {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        left: 0;
        background: #fff;
        gap: 0.5rem;
        padding: 8px 1rem 6px;
        .--item {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          text-align: center;
          padding: 12px 0;
          background: #fff;
          border-radius: 25px;
          font-weight: 600;
          color: var(--color-2);
          &.active {
            background: rgb(117 171 69 / 20%);
            color: var(--color-1);
          }
        }
      }
    }
  }
  .--footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 10;
    position: fixed;
    bottom: 0;
    pointer-events: none;
    .footer-left {
      max-width: 50%;
      width: 100%;
      .--listLogo {
        display: none;
        img {
          max-height: 24px;
          object-fit: contain;
        }
      }
      .--item {
        display: flex !important;
        justify-content: flex-start;
      }
    }
    .footer-right {
      display: flex;
      align-items: center;
      gap: 12px;
      background: linear-gradient(-90deg, #000 60%, transparent);
      color: #fff;
      padding: 0.5rem 0.5rem 0.5rem 8rem;

      .--developers {
        transition: all 0.13s ease;
        font-size: 0.9rem;
        font-weight: 500;
        color: var(--color-smoke);
        &:hover {
          color: var(--color-1);
        }
      }
      .--view {
        position: relative;
        display: flex;
        gap: 8px;
        align-items: center;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          border-left: 1px solid rgba(55, 65, 81, 0.16);
          height: 16px;
          right: calc(100% + 16px);
        }
      }
    }
    @media (max-width: 640px) {
      flex-flow: column;
      gap: 8px;
      align-items: flex-end;
      display: none;
      .footer-right {
        padding: 0.5rem;
        justify-content: flex-end;
        text-align: right;
      }
    }
  }

  .main {
    flex: 1;
    position: relative;
    overflow: hidden;
    @media (max-width: 640px) {
      padding-bottom: 50px;
    }
  }
`;
