/* eslint-disable no-nested-ternary */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@type/index';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.app || initialState;

export const selectApp = createSelector(
  [selectDomain],
  dashboardState => dashboardState,
);

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.loading,
);

export const selectIsError = createSelector(
  [selectDomain],
  state => state.error,
);

export const selectIsSuccess = createSelector(
  [selectDomain],
  state => state.success,
);

export const selectIsLogged = createSelector(
  [selectDomain],
  state => state.isLogged,
);

export const selectIsShowDialogLogin = createSelector(
  [selectDomain],
  state => state.isShowDialogLogin,
);

export const selectInfoUser = createSelector(
  [selectDomain],
  state => state.infoUser,
);

export const selectProvinceSelected = createSelector(
  [selectDomain],
  state => state.provinceSelected,
);

export const selectTourFlycam = createSelector(
  [selectDomain],
  state => state.tourFlycam,
);

export const selectTrans = createSelector([selectDomain], state => state.trans);

export const selectLayout = createSelector(
  [selectDomain],
  state => state.layout,
);

export const selectNotification = createSelector(
  [selectDomain],
  state => state.notification || undefined,
);

export const selectFaq = createSelector([selectDomain], state => state.faq);

export const selectListPlaceCategory = createSelector(
  [selectDomain],
  state => state.listPlaceCategory,
);

export const selectListLocation = createSelector(
  [selectDomain],
  state => state.listLocation,
);

export const selectOptionsFilterListLocation = createSelector(
  [selectDomain],
  state => state.optionsFilterListLocation,
);
