import appSagas from '@containers/App/sagas';
import homeSagas from '@containers/Home/sagas';
import { all } from 'redux-saga/effects';
import aboutSagas from '@containers/About/saga';
import sponsorSagas from '@containers/Sponsor/saga';

export default function* rootSaga() {
  try {
    yield all([...appSagas, ...aboutSagas, ...sponsorSagas, ...homeSagas]);
  } catch {}
}
