/* eslint-disable prettier/prettier */
import { BaseXHR } from '@utils/axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  actions,
  GET_INFO_USER,
  GET_DETAIL_PROJECT,
  changeProjectAction,
  GET_LIST_HOTSPOT_FLYCAM,
  GET_LIST_COMMENT_GOTEREST,
  UPDATE_COUNT_VIEW_SCENE,
} from './slice';
import { getToursWithinRadiusApi, updateViewCountApi } from 'service/tourApi';
import { getListingCommentApi } from 'service/masterDataApi';
import { actions as actionsApp } from '@containers/App/slice';
import { selectDetailProject } from './selectors';

export function* getDetailProject({ payload }: any) {
  try {
    let url = '';
    if (payload.isPayment) url = `project/getPaymentPublic?id=${payload.id}`;
    else if (payload.isPublic)
      url = `project/getPublic?${
        payload.domain ? `domain=${payload.domain}` : `slug=${payload.id}`
      }`;
    else url = `project/getPreview?slug=${payload.id}`;
    const response = payload.isPublic
      ? yield call(BaseXHR.$get, url)
      : yield call(BaseXHR.$getWithAuth, url, {
          headers: {
            Authorization: `Bearer ${payload.token}`,
          },
        });

    yield put(GET_DETAIL_PROJECT.success(response.data.data));
    // if (response.data?.data?.scenes)
    //   yield put(actions.setListScene(response.data.data.scenes));
  } catch (err) {
    console.log(err);
    yield put(GET_DETAIL_PROJECT.failure());
  }
}

export function* getInfoUser({ payload }: any) {
  try {
    const { data } = yield call(BaseXHR.$getWithAuth, '/account/profile', {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    });

    yield put(GET_INFO_USER.success(data.data));
  } catch (err) {
    yield put(GET_INFO_USER.failure());
  }
}

export function* getListTourWithinRadius({ payload }: any) {
  try {
    const { data } = yield call(getToursWithinRadiusApi, payload);
    yield put(GET_LIST_HOTSPOT_FLYCAM.success(data.data));
  } catch (err) {
    yield put(GET_LIST_HOTSPOT_FLYCAM.failure());
  }
}

export function* updateViewCountScene({ payload }: any) {
  try {
    const project = yield select(selectDetailProject);
    const { data } = yield call(updateViewCountApi, payload);

    yield put(
      UPDATE_COUNT_VIEW_SCENE.success({
        ...payload,
        view_count: data.data.view_count,
      }),
    );
    yield put(
      actionsApp.updateCountViewLocation({
        projectId: project?.id,
      }),
    );
  } catch (err) {
    yield put(UPDATE_COUNT_VIEW_SCENE.failure());
  }
}

export function* getListCommentGoterest({ payload }: any) {
  try {
    const { data } = yield call(getListingCommentApi, payload);
    yield put(GET_LIST_COMMENT_GOTEREST.success(data.data));
  } catch (err) {
    yield put(GET_LIST_COMMENT_GOTEREST.failure());
  }
}

export function* onChangeProject({ payload }: any) {
  try {
    yield put(actions.resetPage());
    yield call(getDetailProject, {
      payload: { id: payload.slug, isPublic: true },
    });
  } catch (err) {}
}

const homeSagas: any = [
  takeLatest(GET_INFO_USER.TRIGGER, getInfoUser),
  takeLatest(GET_DETAIL_PROJECT.TRIGGER, getDetailProject),
  takeLatest(GET_LIST_HOTSPOT_FLYCAM.TRIGGER, getListTourWithinRadius),
  takeLatest(UPDATE_COUNT_VIEW_SCENE.TRIGGER, updateViewCountScene),
  takeLatest(GET_LIST_COMMENT_GOTEREST.TRIGGER, getListCommentGoterest),
  takeLatest(changeProjectAction, onChangeProject),
];

export default homeSagas;
