import { BaseXHRPimCore } from '@utils/axios';

interface I_PayloadLogin {
  access_token: string;
  type: 'facebook' | 'google';
}

export interface I_PayloadAddCommentGoterest {
  content: string;
  sceneId: string;
  projectId: string;
  locationId: number;
  parentId?: number;
  ath: number;
  atv: number;
  images: File[];
}

export const loginApi = (payload: I_PayloadLogin) =>
  BaseXHRPimCore.$post('vi/auth/login-by-app', payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export const getProfileApi = payload =>
  BaseXHRPimCore.$get('vi/customer/get-profile', payload);

export const getLayoutApi = payload =>
  BaseXHRPimCore.$get('vi/document/layout', payload);

export const getTransApi = payload =>
  BaseXHRPimCore.$get('vi/translator/get?channel=messages', payload);

export const getPageApi = payload =>
  BaseXHRPimCore.$get('vi/document/page/get-by-name', payload);

export const getAboutApi = payload =>
  BaseXHRPimCore.$get('vi/document/page/get-by-name?name=introduce', payload);

export const getFaqApi = payload =>
  BaseXHRPimCore.$get('vi/document/page/get-by-name?name=faq', payload);

export const getSponsorApi = payload =>
  BaseXHRPimCore.$get('vi/document/page/get-by-name?name=sponsor', payload);

export const getListPlaceCategoryApi = payload =>
  BaseXHRPimCore.$get(`vi/place-category/listing`, payload);

export const getListLocationApi = payload =>
  BaseXHRPimCore.$get(`vi/location/listing`, payload);

export const getContributeApi = payload =>
  BaseXHRPimCore.$post('vi/contribute/new-contribute', payload);

export const getListingCommentApi = payload =>
  BaseXHRPimCore.$get('vi/comment/listing', payload);

export const createCommentApi = (payload: FormData) =>
  BaseXHRPimCore.$post('vi/comment/create', payload);

export const toggleLikeCommentApi = payload =>
  BaseXHRPimCore.$post('vi/comment/like-comment', payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
