import { createRoutine } from 'redux-saga-routines';
import AppState, {
  I_Layout,
  I_Translations,
  I_Faq,
  I_Notification,
  I_OptionsFilterListLocation,
  I_User,
} from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { I_Location, I_PlaceCategory } from '@containers/Home/types';
import { KEY_COOKIE } from '@type/customType';

// The initial state of the App container
export const initialState: AppState = {
  loading: false,
  success: false,
  error: false,

  isLogged: false,
  isShowDialogLogin: false,
  infoUser: null,

  provinceSelected: '',
  trans: null,
  layout: null,
  faq: null,
  notification: {
    message: '',
    show: false,
    title: '',
    type: '',
  },
  tourFlycam: null,
  listPlaceCategory: [],
  optionsFilterListLocation: {
    categoryIds: [],
    nameLocation: '',
  },
  listLocation: [],
};

export const LOGIN = createRoutine('app/login');
export const GET_ME = createRoutine('app/getMe');
export const GET_APP_INFO = createRoutine('app/getAppInfo');
export const GET_LIST_PLACE_CATEGORY = createRoutine(
  'App/getListPlaceCategory',
);
export const GET_LIST_LOCATION = createRoutine('App/getListLocation');

const appSlice = createSlice({
  name: 'app',
  initialState,

  reducers: {
    updateCountViewLocation(
      state,
      action: PayloadAction<{ projectId: string }>,
    ) {
      state.listLocation = state.listLocation.map(item =>
        item.projectId?.id === action.payload.projectId
          ? { ...item, countView: item.countView + 1 }
          : item,
      );
    },
    changeIsLogged(state, action: PayloadAction<{ isLogged: boolean }>) {
      state.isLogged = action.payload.isLogged;
    },

    changeIsShowDialogLogin(state, action: PayloadAction<boolean>) {
      state.isShowDialogLogin = action.payload;
    },

    setInfoUser(state, action: PayloadAction<I_User | null>) {
      state.infoUser = state.infoUser
        ? { ...state.infoUser, ...action.payload }
        : action.payload;
    },

    updateInfoUser(state, action: PayloadAction<Partial<I_User>>) {
      if (state.infoUser)
        state.infoUser = { ...state.infoUser, ...action.payload };
    },

    setTourFlycam(state, action: PayloadAction<string>) {
      state.tourFlycam = action.payload;
    },

    setProvinceSelected(state, action: PayloadAction<string>) {
      state.provinceSelected = action.payload;
    },

    onChangeOptionsFilterListLocation(
      state,
      action: PayloadAction<Partial<I_OptionsFilterListLocation>>,
    ) {
      const { categoryIds, nameLocation } = action.payload;
      if (nameLocation !== undefined)
        state.optionsFilterListLocation.nameLocation = nameLocation;
      if (categoryIds !== undefined) {
        state.optionsFilterListLocation.categoryIds = categoryIds;
        state.optionsFilterListLocation.nameLocation = '';
      }
    },

    setNotification(state, action: PayloadAction<I_Notification>) {
      const { show, title, message, type } = action.payload;
      if (type !== undefined) state.notification.type = type;
      if (title !== undefined) state.notification.title = title;
      if (message !== undefined) state.notification.message = message;
      if (show !== undefined) state.notification.show = show;
    },

    logout(state) {
      state.isLogged = initialState.isLogged;
      state.infoUser = initialState.infoUser;
      document.cookie = `${KEY_COOKIE.TOKEN}=; path=/; SameSite=None; Secure`;
      state = initialState;
    },

    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
  },

  extraReducers: builder => {
    builder.addCase(
      HYDRATE as string,
      (state: AppState, action: PayloadAction<any>) => ({
        ...state,
        ...action.payload.app,
      }),
    );

    builder
      .addCase(GET_APP_INFO.TRIGGER, state => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(
        GET_APP_INFO.SUCCESS,
        (
          state,
          action: PayloadAction<{
            layout: I_Layout;
            trans: I_Translations;
            faq: I_Faq;
          }>,
        ) => {
          const { layout, faq, trans } = action.payload;
          state.layout = layout;
          state.trans = trans;
          state.faq = faq;
          const defaultProvince =
            action.payload?.layout?.header?.jsonProvinces.filter(
              item => item.name === 'Hà Nội',
            );
          if (defaultProvince && !state.provinceSelected) {
            state.provinceSelected = defaultProvince[0].slug;
            state.tourFlycam = defaultProvince[0].projectId;
          }
          state.loading = false;
          state.success = true;
          state.error = false;
        },
      )
      .addCase(GET_APP_INFO.FAILURE, state => {
        state.loading = false;
        state.success = false;
        state.error = true;
      });

    builder
      .addCase(GET_LIST_PLACE_CATEGORY.TRIGGER, state => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(
        GET_LIST_PLACE_CATEGORY.SUCCESS,
        (state, action: PayloadAction<I_PlaceCategory[]>) => {
          state.listPlaceCategory = action.payload;
          state.loading = false;
          state.success = true;
          state.error = false;
        },
      )
      .addCase(GET_LIST_PLACE_CATEGORY.FAILURE, state => {
        state.loading = false;
        state.success = false;
        state.error = true;
      });

    builder
      .addCase(GET_LIST_LOCATION.TRIGGER, state => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(
        GET_LIST_LOCATION.SUCCESS,
        (state, action: PayloadAction<I_Location[]>) => {
          state.listLocation = action.payload;
          state.loading = false;
          state.success = true;
          state.error = false;
        },
      )
      .addCase(GET_LIST_LOCATION.FAILURE, state => {
        state.loading = false;
        state.success = false;
        state.error = true;
      });

    builder
      .addCase(LOGIN.TRIGGER, state => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(LOGIN.SUCCESS, state => {
        state.loading = false;
        state.success = true;
        state.error = false;
      })
      .addCase(LOGIN.FAILURE, state => {
        state.loading = false;
        state.success = false;
        state.error = true;
      });

    builder
      .addCase(GET_ME.TRIGGER, state => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(GET_ME.SUCCESS, (state, action: PayloadAction<I_User>) => {
        state.infoUser = action.payload;
        state.loading = false;
        state.success = true;
        state.error = false;
      })
      .addCase(GET_ME.FAILURE, state => {
        state.loading = false;
        state.success = false;
        state.error = true;
      });
  },
});

export const { actions, reducer, name: sliceKey } = appSlice;

export default reducer;
